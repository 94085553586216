import * as React from 'react';
import GetStarted from '../components/GetStarted';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

const NotFoundPage = (props) => (
  <Layout {...props}>
    <SEO title="404: Not found" />
    <div className="container">
      <div className="not-found-main">
        <div className="row mobile-wrap mobile-col-reverse">
          <div className="column">
            <h1>Page not found!</h1>
            <p style={{ maxWidth: 500 }}>
              Sorry, but the page you were looking for could not be found.
              Please <a href="#!">report to us</a> if you think this is an
              error.
            </p>
            <a
              className="button button-green"
              style={{ marginTop: 20 }}
              href="/">
              Go to Homepage <span className="chevron" />
            </a>
          </div>

          <div className="column">
            <p className="not-found-text">404</p>
          </div>
        </div>
      </div>
    </div>
    <GetStarted />
  </Layout>
);

export default NotFoundPage;
